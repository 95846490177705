import axios from "axios";
import siteConfig from '@iso/config/site.config';
import Swal from 'sweetalert2';

import AppHelper from "../helpers/AppHelper";

export const API = axios.create({ baseURL: siteConfig.apiUrl });

var storagename, storageurl;
if (window.location.pathname.includes('webpanel')) {
    storagename = "id_token";
    storageurl  = "/webpanel/login";
} else {
    storagename = "STD_ACCESS_TOKEN";
    storageurl  = "/";
}

API.interceptors.request.use(config => {
    let source = axios.CancelToken.source();
    config.cancelToken = source.token;
    config.headers.Authorization = "Bearer "+(localStorage.getItem(storagename)!=null ? localStorage.getItem(storagename).replace(/^"(.*)"$/, '$1') : '');;
    return config;
}, error => {
    return Promise.reject(error);
});

API.interceptors.response.use(response => {
    if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}, async (error) => {
    if (error.response.status) {
        switch (error.response.status) {
            case 400:
                Swal.fire({
                    title: 'Error',
                    showCancelButton: false,
                    icon: 'error',
                    html: error.response.data.error.includes("Postal code") ? "Postal code may contain no more than 8 letter or number characters" : error.response.data.error,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
                break;
            case 404:
                break;
            case 401:
                Swal.fire({
                    title: 'Error',
                    showCancelButton: false,
                    icon: 'error',
                    html: "Session expired please login!",
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    localStorage.removeItem(storagename);
                    window.location.href = storageurl;
                });
                break;
            case 403:
                break;
            case 500:
                if(error.response.data.message == "Token Expired" || error.response.data.message == "A token is required")
                    Swal.fire({
                        title: 'Error',
                        showCancelButton: false,
                        icon: 'error',
                        html: "Unauthorized, Return back to login.",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        localStorage.removeItem(storagename);
                        window.location.href = storageurl;
                    });
                else{
                    Swal.fire({
                        title: 'Error',
                        showCancelButton: false,
                        icon: 'error',
                        html: error.response.data.message,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                }
                break;
            case 502:
                break;
            case 422:
                break;
            default:
                break;
        }
        return Promise.reject(error.response);
    }
    return Promise.resolve(error.response);
});

export const http = {
    getReq: function (endPoint, options = {}) {
        return API.get(`${AppHelper.getSiteType()}${endPoint}`, options);
    },
    createReq: function (endPoint, datas, options = {}) {        
        return API.post(endPoint == "/get_website_detail" ? endPoint : `${AppHelper.getSiteType()}${endPoint}`, datas, options);
    },
    updateReq: function (endPoint, datas, options = {}) {
        return API.post(`${AppHelper.getSiteType()}${endPoint}`, datas, options);
    },
    editReq: function (endPoint, datas, options = {}) {
        return API.put(`${AppHelper.getSiteType()}${endPoint}`, datas, options);
    },
    deleteReq: function (endPoint, options = {}) {
        return API.delete(`${AppHelper.getSiteType()}${endPoint}`, { data: options });
    },
}
